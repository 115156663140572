import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {FiEdit} from 'react-icons/fi';
import {createColumnHelper} from '@tanstack/react-table';
import Table from 'components/table/Table';
import Card from 'components/card';
import {dateFormat} from 'utils/date';
import {convertToRupiah} from '../../../utils/general';
import Datepicker from 'react-tailwindcss-datepicker';

const List = ({request}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [pembaharuan, setPembaharuan] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [dataProvinces, setProvinces]     = useState([]);
    const [dataDistrict, setDistrict]       = useState([]);
    const [dataSubdistrict, setSubdistrict] = useState([]);
    const [dataWards, setWards]             = useState([]);
    const [dataHamlets, setHamlets]         = useState([]);
    const [provinceId, setprovinceId]       = useState('');
    const [districtId, setDistrictId]       = useState('');
    const [subdistrictId, setSubdistrictId] = useState('');
    const [hamletId, setHamletId]           = useState('');
    const [wardsId, setWardsId]             = useState('');

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });
    }, [request]);

    const handleProvince = (event) => {
        setprovinceId(event.target.value);
        setDistrictId('');
        const params = {
            province_id: event.target.value,
        };
        request.get('/districts', {params}).then((response) => {
            setDistrict(response);
        });
    };

    const handleDistrict = (event) => {
        setDistrictId(event.target.value);
        setSubdistrictId('');
        const params = {
            district_id: event.target.value,
        };
        request.get('/subdistricts', {params}).then((response) => {
            setSubdistrict(response);
        });
    };

    const handleSubdistrict = (event) => {
        setSubdistrictId(event.target.value);
        setWardsId('');
        const params = {
            subdistrict_id: event.target.value,
        };
        request.get('/wards', {params}).then((response) => {
            setWards(response);
        });
    };

    const handleWard = (event) => {
        setWardsId(event.target.value);
        setHamletId('');
        const params = {
            ward_id: event.target.value,
        };
        request.get('/hamlets', {params}).then((response) => {
            setHamlets(response);
        });
    };

    const handleHamlet = (event) => {
        setHamletId(event.target.value);
    };

    const [value, setValue] = useState({
        startDate: null,
        endDate: null,
    });

    const handleChangePembaharuan = (event) => {
        setPembaharuan(event.target.value);
    };

    const handleValueChange = (newValue) => {
        setValue(newValue);
    };

    const selectDay = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
    ];

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };
    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                subscription_status: selectedStatus,
                start_date: value?.startDate,
                end_date: value?.endDate,
                date_invoice: pembaharuan,
                province_id: provinceId,
                district_id: districtId,
                subdistrict_id: subdistrictId,
                ward_id: wardsId,
                hamlet_id: hamletId,
            };
            try {
                const response = await request.get('/subscriptions', {params});
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, selectedStatus, value, pembaharuan, provinceId, districtId, subdistrictId, wardsId, hamletId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleStatusChangeDropdown = (event, row) => {
        setNewStatus(event.target.value === 'active' ? 'waiting-payment' : event.target.value);
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const confirmStatusChange = async () => {
        setRefresh(true);
        if (!selectedRow) return;
        try {
            await request.post(`subscriptions/${selectedRow.subscription_id}/status`, { subscription_status: newStatus });
            const updatedData = data.map(item =>
                item.subscription_id === selectedRow.subscription_id ? { ...item, subscription_status: newStatus } : item
            );
            setData(updatedData);
            toast.success('Status berhasil diperbarui');
        } catch (err) {
            toast.error('Gagal memperbarui status');
        }
        setIsModalOpen(false);
    };

    const statusData = {
        active: {
            label: 'Active',
            color: 'badge bg-[#36D3991A] text-[#258D67]',
        },
        'non-active': {
            label: 'Non-Active',
            color: 'badge bg-[#FF3A441A] text-[#FF3A44]',
        },
        pending: {
            label: 'Libur Langganan',
            color: 'badge bg-[#f1ff3a1A] text-[#b8b81d]',
        },
        'waiting-payment': {
            label: 'Menunggu Pembayaran',
            color: 'badge bg-[#0000001A] text-[#0a0a0a]',
        },
        expired: {
            label: 'Kadaluarsa',
            color: 'badge bg-[#e6462e1A] text-[#E6462E]',
        },
    };

    const getAvailableStatusOptions = (subscription_status) => {
        const getStatusOption = (key) => ({
            value: key,
            label: statusData[key].label,
            className: statusData[key].color,
        });

        const statusOptions = {
            active: ['non-active', 'pending'],
            'non-active': ['active'],
            pending: ['active', 'non-active'],
        };

        return statusOptions[subscription_status]?.map(getStatusOption) || [];
    };

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_number.customer.customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div className='min-w-max max-w-2xl'>
                        <Link
                            className='max-w-xs truncate text-sm hover:text-success'
                            aria-disabled={true}
                            to={`/customers/${info.row.original.customer_number.customer_id}`}
                        >
                            {info.getValue()}
                            <br/>{info.row.original.customer_number.c_number_value}
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_number.customer.reseller.reseller_name', {
                header: () => 'Marketing',
                cell: (info) => (
                    <div
                        className='max-w-xs truncate text-sm'>{info.getValue() === undefined ? '-' : info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('package.package_name', {
                header: () => 'Paket',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_fee', {
                header: () => 'Biaya',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {convertToRupiah(info.getValue(), true)}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_invoice_day', {
                header: () => 'tanggal pembaharuan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue()}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_start_date', {
                header: () => 'Tanggal Mulai',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_expiry_date', {
                header: () => 'Tanggal Expired',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_status', {
                header: () => 'Status',
                cell: (info) => {
                    const rowData = info.row.original;
                    const currentStatus = rowData.subscription_status;
                    const invoiceStatus = rowData.invoice?.invoice_status;
                    const availableOptions = getAvailableStatusOptions(
                        currentStatus,
                        invoiceStatus,
                        rowData.subscription_invoice_day
                    );

                    return (
                        <select
                            className={`select select-sm w-52 ${statusData[currentStatus]?.color || ''}`}
                            value={currentStatus}
                            onChange={(event) => handleStatusChangeDropdown(event, rowData)}
                            disabled={currentStatus === 'waiting-payment'}
                        >
                            <option value={currentStatus} disabled>
                                {statusData[currentStatus]?.label || currentStatus}
                            </option>

                            {availableOptions.map((option) => (
                                option.value !== currentStatus && (
                                    <option key={option.value} value={option.value} className={statusData[option.value]?.color}>
                                        {option.label}
                                    </option>
                                )
                            ))}
                        </select>

                    );
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('action', {
                header: () => 'Aksi',
                cell: (info) => (
                    <>
                        <Link
                            to={`/subscriptions/${info.row.original.subscription_id}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24}/>
                        </Link>
                    </>
                ),
                footer: (info) => info.column.id,
                enableSorting: false,
                enableGrouping: false,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h3 className="text-lg font-semibold">Konfirmasi Perubahan Status</h3>
                        <p className="mt-2 text-sm">Apakah Anda yakin ingin mengubah status menjadi</p>
                        <p className="mt-2 text-sm"><strong>{newStatus === 'waiting-payment' ? 'Active' : statusData[newStatus]?.label}?</strong></p>
                        <div className="mt-4 flex justify-end gap-2">
                            <button
                                className="px-4 py-2 bg-gray-300 font-bold rounded"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Batal
                            </button>
                            <button
                                className="px-4 py-2 bg-yellow-500 text-black font-bold rounded"
                                onClick={confirmStatusChange}
                            >
                                Ubah
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Daftar Langganan</h2>
                <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleStatusChange}
                                        className='select select-sm max-w-xs'
                                        value={selectedStatus}
                                    >
                                        <option value='' selected>
                                            Semua Status
                                        </option>
                                        <option value='active'>Active</option>
                                        <option value='non-active'>Non-Active</option>
                                        <option value='pending'>Libur Langganan</option>
                                        <option value='waiting-payment'>Menunggu Pembayaran</option>
                                        <option value='expired'>Expired</option>
                                    </select>
                                    <Datepicker
                                        toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                        containerClassName='input input-sm relative flex bg-white justify-self-start'
                                        inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                                        value={value}
                                        onChange={handleValueChange}
                                        showShortcuts={true}
                                        placeholder='Pilih Tanggal Registrasi'
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleChangePembaharuan}
                                        className='select select-sm max-w-xs'
                                        value={pembaharuan}
                                    >
                                        <option value='' selected>
                                            Semua Pembaharuan
                                        </option>
                                        {selectDay.map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleProvince}
                                        value={provinceId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Provinsi
                                        </option>
                                        {dataProvinces.map((item) => (
                                            <option key={item.province_id} value={item.province_id}>
                                                {item.province_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleDistrict}
                                        value={districtId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Kabupaten
                                        </option>
                                        {dataDistrict.map((item) => (
                                            <option key={item.district_id} value={item.district_id}>
                                                {item.district_name}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleSubdistrict}
                                        value={subdistrictId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Kecamatan
                                        </option>
                                        {dataSubdistrict.map((item) => (
                                            <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                                {item.subdistrict_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleWard}
                                        value={wardsId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Desa
                                        </option>
                                        {dataWards.map((item) => (
                                            <option key={item.ward_id} value={item.ward_id}>
                                                {item.ward_name}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleHamlet}
                                        value={hamletId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Dusun
                                        </option>
                                        {dataHamlets.map((item) => (
                                            <option key={item.hamlet_id} value={item.hamlet_id}>
                                                {item.hamlet_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </>
                        )}
                        actions={() => (
                            <Link
                                to='/subscriptions/add'
                                type='button'
                                className='btn btn-secondary btn-sm ml-1'
                            >
                                Tambah
                            </Link>
                        )}
                    />
                </div>
            </Card>
        </>
    );
};
export default List;
