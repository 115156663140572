import { useMemo, useReducer, useState, useEffect, useRef } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import noDataImg from 'assets/svg/no-data.svg';

const Table = ({
  columns,
  data,
  isLoading,
  fetchData,
  setRefresh,
  pageCount: controlledPageCount,
  totalRow,
  actions: Actions,
  filters: Filters,
  canSort,
  canGroup,
}) => {
  const [grouping, setGrouping] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [expandSearch, setExpandSearch] = useState(false);
  const searchInputRef = useRef(null);
  const rerender = useReducer(() => ({}), {})[1];
  const finalData = useMemo(
    () => (isLoading ? Array(10).fill({}) : data),
    [isLoading, data]
  );
  const finalColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton height={24} />,
          }))
        : columns,
    [isLoading, columns]
  );

  const table = useReactTable({
    columns: finalColumns,
    data: finalData,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    state: {
      grouping,
      sorting,
    },
    pageCount: controlledPageCount,
    autoResetAll: false,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
  });

  const handleExpandClick = () => {
    setExpandSearch(true);
  };

  const handleExpandBlur = () => {
    setExpandSearch(false);
  };

  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows;
    const [keyword, setKeyword] = useState(globalFilter);

    const onChange = (event) => {
      if (event.keyCode === 13) {
        setGlobalFilter(keyword || undefined);
      }
    };

    useEffect(() => {
      if (expandSearch === true) {
        searchInputRef.current.focus();
      }
    }, [expandSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <div className='col lg:grid-row-6 grid grid-cols-3 gap-1 lg:grid lg:grid-flow-col xs:grid-cols-1 '>
          <div className='relative '>
            <button
              type='button'
              onClick={handleExpandClick}
              className='absolute left-0 top-[5px] flex items-center pl-3 md:pointer-events-none'
            >
              <svg
                className='h-5 w-5 text-gray-500'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
            <input
              id='table-search'
              ref={searchInputRef}
              value={keyword || ''}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onKeyDown={(e) => onChange(e)}
              onBlur={handleExpandBlur}
              type='search'
              className='input input-sm w-full pl-10'
              placeholder={`${count} data...`}
            />
          </div>
          {Filters && <Filters />}
        </div>
        {Actions && (
          <div className='justify-end'>
            <Actions />
          </div>
        )}
      </>
    );
  };

  const {
    pagination: { pageIndex, pageSize },
    globalFilter,
  } = table.getState();

  useEffect(() => {
    let search = globalFilter === undefined ? '' : globalFilter;
    const sort = sorting
      .filter((item) => item.id !== undefined)
      .map((item) => ({
        field: item.id,
        direction: item.desc ? 'desc' : 'asc',
      }));

    fetchData(pageSize, pageIndex + 1, search, sort);
  }, [fetchData, pageIndex, pageSize, globalFilter, sorting]);

  return (
    <div className='rounded-xl shadow'>
      <div className='flex flex-col items-start justify-between gap-y-1 rounded-t-xl bg-primary p-2 md:flex-row'>
        <GlobalFilter
          preGlobalFilteredRows={totalRow}
          globalFilter={globalFilter}
          setGlobalFilter={table.setGlobalFilter}
        />
      </div>
      <div className='scrollbar-thumb-primary-200 scrollbar-track-primary-10 relative overflow-x-auto !text-sm scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full'>
        <table className='w-full text-left'>
          <thead className='bg-gray-200 text-xs uppercase text-mirage-500'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope='col'
                    className='px-2 py-3'
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {header.column.getCanGroup() && canGroup ? (
                          // If the header can be grouped, let's add a toggle
                          <button
                            {...{
                              onClick: header.column.getToggleGroupingHandler(),
                              style: {
                                cursor: 'pointer',
                                float: 'left',
                                marginRight: '0.2rem',
                              },
                            }}
                          >
                            {header.column.getIsGrouped()
                              ? `🛑(${header.column.getGroupedIndex()}) `
                              : `🏷️ `}
                          </button>
                        ) : null}{' '}
                        <div
                          className='flex items-center'
                          {...{
                            className:
                              'flex items-center' +
                                header.column.getCanSort() && canSort
                                ? ' cursor-pointer select-none'
                                : '',
                            onClick:
                              canSort &&
                              header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-thunder'>
            {table.getRowModel().rows.length === 0 && !isLoading ? (
              <tr>
                <td colSpan='100%'>
                  <div className='mt-4 flex flex-col items-center justify-center'>
                    <div className='w-full overflow-hidden bg-white'>
                      <div className='flex flex-col items-center justify-center bg-gray-100 px-6 py-4'>
                        <img
                          src={noDataImg}
                          alt='Empty Table'
                          width={256}
                          height={256}
                        />
                        <h2 className='mt-4 font-bold text-mirage-500'>
                          Data Kosong
                        </h2>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row, i) => (
                <tr
                  key={row.id}
                  className={`${
                    i % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  } border-b hover:!bg-gray-50`}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...{
                          key: cell.id,
                          style: {
                            background: cell.getIsGrouped()
                              ? '#0aff0082'
                              : cell.getIsAggregated()
                              ? '#ffa50078'
                              : cell.getIsPlaceholder()
                              ? '#ff000042'
                              : '',
                          },
                        }}
                        className='px-2 py-2'
                      >
                        {cell.getIsGrouped() ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <button
                              {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {
                                  cursor: row.getCanExpand()
                                    ? 'pointer'
                                    : 'normal',
                                },
                              }}
                            >
                              {row.getIsExpanded() ? '👇' : '👉'}{' '}
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}{' '}
                              ({row.subRows.length})
                            </button>
                          </>
                        ) : cell.getIsAggregated() ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          flexRender(
                            cell.column.columnDef.aggregatedCell ??
                              cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <nav
          className='flex flex-col items-start justify-between gap-y-2 px-4 pt-4 md:flex-row md:items-center'
          aria-label='Table navigation'
        >
          <ul className='inline-flex items-center -space-x-px'>
            <li>
              <button
                className='btn btn-secondary btn-sm rounded-r-none leading-tight'
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </button>
            </li>
            <li>
              <button
                className='btn btn-secondary btn-sm rounded-none leading-tight'
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </button>
            </li>
            <li>
              <button
                className='btn btn-secondary btn-sm rounded-none leading-tight'
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </button>
            </li>
            <li>
              <button
                className='btn btn-secondary btn-sm rounded-l-none leading-tight'
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </button>
            </li>
          </ul>
          <span className='flex items-center gap-1'>
            <div>Halaman</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} dari{' '}
              {isLoading ? 1 : table.getPageCount()}
            </strong>
          </span>
          <span className='flex items-center gap-1'>
            | Pergi ke halaman:
            <input
              type='number'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className='input input-bordered input-sm w-16'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            className='select select-bordered select-sm'
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Tampilkan {pageSize}
              </option>
            ))}
          </select>
        </nav>
        <div className='flex justify-center gap-4 py-3'>
          <div className='text-primary-400 font-bold'>
            {isLoading ? 0 : table.getRowModel().rows.length} Baris
          </div>
          <button
            onClick={() => rerender()}
            className='text-primary-600 font-bold'
          >
            Rerender Ulang
          </button>
          <button
            onClick={() => setRefresh(true)}
            className='text-primary-600 font-bold'
          >
            Refresh Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
