import React, {useCallback, useMemo, useState} from "react";
import Card from 'components/card';
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import Table from '../../../components/table/Table';
import {Link} from "react-router-dom";
import {FiEdit, FiEye} from "react-icons/fi";
import {FaWhatsapp} from "react-icons/fa";
import {dateFormat} from "../../../utils/date";
import {convertToRupiah} from "../../../utils/general";

const List = ({ request }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [status, setStatus] = useState('');
    const [refresh, setRefresh] = useState(false);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                reseller_status: status,
            };

            try {
                const response = await request.get('/resellers', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, status] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller_code', {
                header: () => 'No Referal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller_whatshap', {
                header: () => 'No. Whatsapp',
                cell: (info) => (
                  <div className="inline-flex max-w-2xl gap-x-1 text-sm">
                    {info.getValue()}
                    <a
                      aria-disabled={true}
                      href={`https://web.whatsapp.com/send?phone=${encodeURIComponent(info.getValue().replace(/^0/, '62'))}&app_absent=0`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-green font-medium"
                    >
                      <FaWhatsapp size={24} />
                    </a>
                  </div>
                ),
              footer: (info) => info.column.id,
            }),
          columnHelper.accessor('reseller_balance', {
            header: () => 'Bonus',
            cell: (info) => (
              <div className="max-w-2xl truncate text-sm">{convertToRupiah(info.getValue(), true)}</div>
            ),
            footer: (info) => info.column.id,
          }),
          columnHelper.accessor('reseller_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'active' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) }
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller_create_date', {
                header: () => 'Tanggal Daftar',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller_id', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link to={`/marketing/${info.getValue()}`} className='font-medium text-success'>
                            <FiEye size={20} />
                        </Link>
                        <Link
                            to={`/marketing/${info.getValue()}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ], [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Daftar Marketing</h2>
                <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>
                                <select
                                    onChange={handleStatusChange}
                                    className='select select-sm max-w-xs'
                                    value={status}
                                >
                                    <option value='' selected>
                                        Semua Status
                                    </option>
                                    <option value='active'>Active</option>
                                    <option value='non-active'>Non-Active</option>
                                </select>
                            </>

                        )}
                        />
                </div>
            </Card>
            </>
    );
};

export default List;
