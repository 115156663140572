import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Card from 'components/card';
import {toast} from 'react-toastify';
import {createColumnHelper} from '@tanstack/react-table';
import {dateFormat} from 'utils/date';
import Table from 'components/table/Table';
import {Link} from 'react-router-dom';
import {FiEdit, FiEye} from 'react-icons/fi';
import {AiFillDelete} from 'react-icons/ai';
import {FaWhatsapp} from 'react-icons/fa';
import Checkbox from 'components/checkbox';

const List = ({request}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLabel, setFileLabel] = useState('');
    const fileInputRef = useRef(null);
    const [maps, setMaps] = useState('');
    const [dataProvinces, setProvinces] = useState([]);
    const [dataDistrict, setDistrict] = useState([]);
    const [dataSubdistrict, setSubdistrict] = useState([]);
    const [dataWards, setWards] = useState([]);
    const [dataHamlets, setHamlets] = useState([]);
    const [provinceId, setprovinceId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [subdistrictId, setSubdistrictId] = useState('');
    const [hamletId, setHamletId] = useState('');
    const [wardsId, setWardsId] = useState('');

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });
    }, [request]);

    const handleProvince = (event) => {
        setprovinceId(event.target.value);
        setDistrictId('');
        const params = {
            province_id: event.target.value,
        };
        request.get('/districts', {params}).then((response) => {
            setDistrict(response);
        });
    };

    const handleDistrict = (event) => {
        setDistrictId(event.target.value);
        setSubdistrictId('');
        const params = {
            district_id: event.target.value,
        };
        request.get('/subdistricts', {params}).then((response) => {
            setSubdistrict(response);
        });
    };

    const handleSubdistrict = (event) => {
        setSubdistrictId(event.target.value);
        setWardsId('');
        const params = {
            subdistrict_id: event.target.value,
        };
        request.get('/wards', {params}).then((response) => {
            setWards(response);
        });
    };

    const handleWard = (event) => {
        setWardsId(event.target.value);
        setHamletId('');
        const params = {
            ward_id: event.target.value,
        };
        request.get('/hamlets', {params}).then((response) => {
            setHamlets(response);
        });
    };

    const handleHamlet = (event) => {
        setHamletId(event.target.value);
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                maps: maps,
                province_id: provinceId,
                district_id: districtId,
                subdistrict_id: subdistrictId,
                ward_id: wardsId,
                hamlet_id: hamletId,
            };

            try {
                const response = await request.get('/customers', {params});
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);

            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, maps, provinceId, districtId, subdistrictId, wardsId, hamletId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleCheckboxChange = async (event) => {
        try {
            await request.patch(`/invoice-switch/${event.original.customer_id}`);
            const isChecked = event.original.customer_invoice === 'on';
            if (isChecked) {
                toast.success('Berhasil mematikan notifikasi');
            } else {
                toast.success('Berhasil menghidupkan notifikasi');
            }
            setRefresh(true)
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const handleChecklistChange = async (event) => {
        try {
            await request.patch(`/maps-checklist/${event.original.customer_id}`);
            const isChecked = event.original.customer_maps === 'on';
            if (isChecked) {
                toast.success('Berhasil mematikan Maps');
            } else {
                toast.success('Berhasil menghidupkan Maps');
            }
            setRefresh(true)
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row?.original?.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_code', {
                header: () => 'Pelanggan',
                cell: (info) => {
                    const cName = info.row.original.customer_name;
                    return (
                        <div className='min-w-max max-w-2xl'>
                            <Link
                                className='max-w-xs truncate text-sm hover:text-success'
                                aria-disabled={true}
                                to={`/customers/${info.row.original.customer_id}`}
                            >
                                {cName}
                                <br/>({info.getValue()})
                            </Link>
                        </div>
                    );
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_whatsapp', {
                header: () => 'No. Whatsapp',
                cell: (info) => (
                    <div className='inline-flex max-w-2xl gap-x-1 text-sm'>
                        {info.getValue()}
                        <a
                            aria-disabled={true}
                            href={`https://web.whatsapp.com/send?phone=${encodeURIComponent(info.getValue().replace(/^0/, '62'))}&app_absent=0`}
                            target='_blank'
                            rel='noreferrer'
                            className='text-green font-medium'
                        >
                            <FaWhatsapp size={24}/>
                        </a>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_email', {
                header: () => 'Email',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue() || '-'}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'active' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : info.getValue() === 'non-active' ? (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) : (
                            <span className='badge'>{info.getValue()}</span>
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_invoice', {
                header: () => 'Notifikasi',
                cell: (info) => {
                    const isChecked = info.row.original.customer_invoice === 'on';
                    return (
                        <div className='form-control max-w-2xl truncate text-sm'>
                            <input
                                type='checkbox'
                                className={`toggle ${isChecked ? 'bg-green-500' : 'bg-red-500'}`}
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(info.row)}
                            />
                        </div>
                    );
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_maps', {
                header: () => 'Maps',
                cell: (info) => {
                    const isChecked = info.row.original.customer_maps === 'on';
                    return (
                        <div className='form-control max-w-2xl truncate text-sm'>
                            <Checkbox
                                checked={isChecked}
                                onChange={() => handleChecklistChange(info.row)}
                            />
                        </div>
                    );
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_create_date', {
                header: () => 'TANGGAL DAFTAR',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_address', {
                header: () => 'ALAMAT PELANGGAN',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue() || '-'}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_id', {
                header: () => 'AKSI',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link
                            aria-disabled={true}
                            to={`/customers/${info.getValue()}`}
                            className='font-medium text-success'
                        >
                            <FiEye size={24}/>
                        </Link>
                        <Link
                            to={`/customers/${info.getValue()}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24}/>
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const label = event.target.files[0]?.name;
        if (file) {
            setSelectedFile(file);
            setFileLabel(label);
            setFileSelected(true);
        }
    };

    const handleImportClick = () => {
        fileInputRef.current.click();
    };

    const importExcel = async () => {
        const id = toast.loading('Mem-proses data...');
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            await request.post('/customers/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important for file uploads
                },
            });
            toast.update(id, {
                render: 'Berhasil Impor Pelanggan',
                type: 'success',
                isLoading: false,
                closeButton: true,
            });
            setRefresh(true);
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) =>
                        toast.update(id, {
                            render: message,
                            type: 'error',
                            isLoading: false,
                            closeButton: true,
                        })
                    )
                );
            } else if (err.response?.data?.message) {
                toast.update(id, {
                    render: err.response.data.message,
                    type: 'error',
                    isLoading: false,
                    closeButton: true,
                });
            } else {
                toast.update(id, {
                    render: err.message,
                    type: 'error',
                    isLoading: false,
                    closeButton: true,
                });
            }
        }

        setSelectedFile(null);
        setFileLabel('');
        setFileSelected(false);
    };

    return (
        <div>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>
                    Daftar Pelanggan
                </h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => {
                            return (
                                <>
                                    <div className='flex flex-col gap-2'>
                                        <select name="" id="" value={maps} className='select select-sm'
                                                onChange={(e) => setMaps(e.target.value)}
                                        >
                                            <option value="">semua</option>
                                            <option value="on">maps on</option>
                                            <option value="off">maps off</option>
                                        </select>
                                        <select
                                            onChange={handleProvince}
                                            value={provinceId}
                                            className='select select-sm max-w-xs'
                                        >
                                            <option value='' disabled selected>
                                                Pilih Provinsi
                                            </option>
                                            {dataProvinces.map((item) => (
                                                <option key={item.province_id} value={item.province_id}>
                                                    {item.province_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <select
                                            onChange={handleDistrict}
                                            value={districtId}
                                            className='select select-sm max-w-xs'
                                        >
                                            <option value='' disabled selected>
                                                Pilih Kabupaten
                                            </option>
                                            {dataDistrict.map((item) => (
                                                <option key={item.district_id} value={item.district_id}>
                                                    {item.district_name}
                                                </option>
                                            ))}
                                        </select>
                                        <select
                                            onChange={handleSubdistrict}
                                            value={subdistrictId}
                                            className='select select-sm max-w-xs'
                                        >
                                            <option value='' disabled selected>
                                                Pilih Kecamatan
                                            </option>
                                            {dataSubdistrict.map((item) => (
                                                <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                                    {item.subdistrict_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <select
                                            onChange={handleWard}
                                            value={wardsId}
                                            className='select select-sm max-w-xs'
                                        >
                                            <option value='' disabled selected>
                                                Pilih Desa
                                            </option>
                                            {dataWards.map((item) => (
                                                <option key={item.ward_id} value={item.ward_id}>
                                                    {item.ward_name}
                                                </option>
                                            ))}
                                        </select>
                                        <select
                                            onChange={handleHamlet}
                                            value={hamletId}
                                            className='select select-sm max-w-xs'
                                        >
                                            <option value='' disabled selected>
                                                Pilih Dusun
                                            </option>
                                            {dataHamlets.map((item) => (
                                                <option key={item.hamlet_id} value={item.hamlet_id}>
                                                    {item.hamlet_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )
                        }}
                        actions={() => (
                            <div className='flex flex-col items-center sm:flex-row xs:flex-row gap-2'>
                                <div className='flex items-center sm:mb-0 sm:mr-1'>
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        className='hidden'
                                        onChange={handleFileChange}
                                    />
                                    <div className='gap-y-1 md:flex-col'>
                                        {fileSelected && (
                                            <div
                                                className='font-[0.875rem] text-decoration-none inline-flex h-[2rem] min-h-[2rem] w-full flex-wrap items-center justify-center rounded-[0.5rem] border-[1px] border-transparent bg-gray-100 px-[0.75rem] text-center text-[0.875rem] leading-[1.25rem] hover:border-opacity-100 sm:w-auto md:rounded-r-none md:pr-[4px]'>
                                                <div className='mr-1 items-center'>
                                                    <div className='max-w-[150px] truncate text-sm'>
                                                        {fileLabel}
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <button
                                                        onClick={() => {
                                                            setFileLabel('');
                                                            setFileSelected(false);
                                                            setSelectedFile(null);
                                                        }}
                                                    >
                                                        <AiFillDelete size={24}/>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {!fileSelected && (
                                            <button
                                                className='btn btn-secondary btn-sm w-full sm:w-auto'
                                                onClick={handleImportClick}
                                            >
                                                <span className='text-sm text-white'>Impor Excel</span>
                                            </button>
                                        )}
                                        {fileSelected && (
                                            <button
                                                className='btn btn-secondary btn-sm mt-2 w-full sm:mt-0 sm:w-auto md:rounded-l-none'
                                                onClick={importExcel}
                                            >
                                                <span className='truncate text-sm text-white'>
                                                  Unggah
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <Link className='btn btn-secondary btn-sm ml-1' to={'/customers/add'}>Tambah</Link>
                            </div>
                        )}
                    />
                </div>
            </Card>
        </div>
    );
};
export default List;
