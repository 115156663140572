import Card from "../../../components/card";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";

const Add = ({ request }) => {
    const navigate                          = useNavigate();
    const { register, handleSubmit }        = useForm();

    const submitHandler = async (data) => {
        try {
            await request.post('/area', data);
            toast.success('Berhasil menyimpan data');
            return navigate('/area');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };
    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Tambah Wilayah</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        {/*/!*Provinsi*!/*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Provinsi</span>
                            </label>
                            <input
                                {...register('province_name', {
                                    required: 'Provinsi harus diisi',
                                })}
                                type='text'
                                className='input input-bordered w-full'
                                placeholder='Masukkan Provinsi'
                            />
                        </div>
                        {/*Kabupaten*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kabupaten</span>
                            </label>
                            <input
                                {...register('district_name', {
                                    required: 'Kabupaten harus diisi',
                                })}
                                type='text'
                                className='input input-bordered w-full'
                                placeholder='Masukkan Kabupaten'
                            />
                        </div>
                        {/*Kecamatan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kecamatan</span>
                            </label>
                            <input
                                {...register('subdistrict_name', {
                                    required: 'Kecamatan harus diisi',
                                })}
                                type='text'
                                className='input input-bordered w-full'
                                placeholder='Masukkan Kecamatan'
                            />
                        </div>
                        {/*Desa*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Desa</span>
                            </label>
                            <input
                                {...register('ward_name', {
                                    required: 'Desa harus diisi',
                                })}
                                type='text'
                                className='input input-bordered w-full'
                                placeholder='Masukkan Desa'
                            />
                        </div>
                        {/*Dusun*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Dusun</span>
                            </label>
                            <input
                                {...register('hamlet_name', {
                                    required: 'Dusun harus diisi',
                                })}
                                type='text'
                                className='input input-bordered w-full'
                                placeholder='Masukkan Dusun'
                            />
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/area' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default Add;