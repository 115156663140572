import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'format-number';
import Datepicker from 'react-tailwindcss-datepicker';
import Table from 'components/table/Table';
import Card from 'components/card';
import { dateFormat } from 'utils/date';

const List = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState('');

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);
      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        start_date: value?.startDate,
        end_date: value?.endDate,
        status: status,
      };
      try {
        const response = await request.get('/submission-update', { params });
        const startIndex = response.from;
        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh, value, status] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_number.customer.customer_name', {
        header: () => 'Nama',
        cell: (info) => (
            <div className='min-w-max max-w-2xl'>
                <Link
                    className='max-w-xs truncate text-sm hover:text-success'
                    aria-disabled={true}
                    to={`/customers/${info.row.original.customer_number.customer_id}`}
                >
                    {info.getValue()}
                </Link>
            </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('subscription.package.package_name', {
        header: () => 'Paket Lama',
        cell: (info) => (
          <div className='min-w-max text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('package.package_name', {
        header: () => 'Paket Baru',
        cell: (info) => (
          <div className='min-w-max text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('package_price', {
        header: () => 'Harga',
        cell: (info) =>
          info.getValue() > 0 ? (
            <div className='min-w-max text-sm'>
              Rp{' '}
              {format({
                prefix: '',
                integerSeparator: '.',
              })(parseFloat(info.getValue()))}
            </div>
          ) : (
            <div>-</div>
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('s_update_create_date', {
        header: () => 'Tanggal',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.getValue() === 'approved' ? (
              <span className='badge badge-success'>{info.getValue()}</span>
            ) : info.getValue() === 'new' ? (
              <span className='badge badge-info'>{info.getValue()}</span>
            ) : info.getValue() === 'rejected' ? (
              <span className='badge badge-warning'>{info.getValue()}</span>
            ) : (
              <span className='badge badge-neutral'>{info.getValue()}</span>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('s_update_id', {
        header: () => 'AKSI',
        cell: (info) => (
          <div className='flex gap-x-1'>
            <Link
              to={`/submission-update/${info.getValue()}/edit`}
              className='font-medium text-warning'
            >
              <FiEdit size={24} />
            </Link>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
        <h2 className='text-xl font-bold text-navy-700'>
          Daftar Pengajuan Upgrade Paket
        </h2>
        <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            filters={() => (
              <>
                <Datepicker
                  maxDate={new Date()}
                  toggleClassName=' h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                  containerClassName='input input-sm relative flex bg-white justify-self-start w-auto '
                  inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                  value={value}
                  onChange={handleValueChange}
                  showShortcuts={true}
                  placeholder='Pilih Tanggal Pengajuan'
                />
                <select
                  onChange={handleStatusChange}
                  className='select select-sm max-w-xs'
                  value={status}
                >
                  <option value='' selected>
                    Semua Status Pengajuan
                  </option>
                  <option value='new'>New</option>
                  <option value='approved'>Approved</option>
                  <option value='rejected'>Rejected</option>
                </select>
              </>
            )}
          />
        </div>
      </Card>
    </>
  );
};

export default List;
