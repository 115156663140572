import {Route, Routes} from "react-router-dom";
import List from "./components/List";
import Details from '../report-discount-invoice/components/Detail';

const ReportDiscountInvoice = (props) => {
  return (
    <Routes>
      <Route path='/' element={<List {...props} />} />
      <Route path='/:id' element={<Details {...props} />} />
    </Routes>
  );
};

export default ReportDiscountInvoice;