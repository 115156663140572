import React, {useCallback, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import Card from "../../../components/card";
import Table from "../../../components/table/Table";
import {Link} from "react-router-dom";
import {FiEdit} from "react-icons/fi";

const List = ({ request }) => {
    const [data, setData]                   = useState([]);
    const [isLoading, setIsLoading]         = useState(false);
    const [pageCount, setPageCount]         = useState(0);
    const [totalRow, setTotalRow]           = useState(0);
    const [refresh, setRefresh]             = useState(false);


    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
            };

            try {
                const response = await request.get('/area', { params });
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const columnHelper = createColumnHelper();

    const columns = useMemo(
        ()=>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('province_name', {
                header: () => 'Provinsi',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('district_name', {
                header: () => 'Kabupaten',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subdistrict_name', {
                header: () => 'Kecamatan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('ward_name', {
                header: () => 'Kelurahan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('hamlet_name', {
                header: () => 'Dusun',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('hamlet_id', {
                header: () => 'AKSI',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link
                            to={`/area/${info.getValue()}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],[] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>Daftar Wilayah</h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        actions={() => (
                            <Link
                                to='/area/add'
                                type='button'
                                className='btn btn-secondary btn-sm ml-1'
                            >
                                Tambah
                            </Link>
                        )}
                    />
                </div>
            </Card>
        </>
    );
}

export default List;
