import Card from '../../../components/card';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { dateFormat } from '../../../utils/date';

const List = ({ request }) => {
  const today = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [active, setActive] = useState('');
  const [nonActive, setNonActive] = useState('');
  const [langganan, setLangganan] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [year, setYear] = useState(today.getFullYear());

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const params = {
        month: month,
        year: year,
      };
      try {
        const response = await request.get('/report-pelanggan', { params });
        setData(response.data);
        setActive(response.total_active);
        setNonActive(response.total_non_active);
        setLangganan(response.total_langganan);
        setIsLoading(false);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
        setIsLoading(false);
        setRefresh(false);
      }
    };
    fetchData();
  }, [refresh, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

  const monthChange = (event) => {
    setMonth(event.target.value);
    setIsLoading(true);
  };
  const yearChange = (event) => {
    setYear(event.target.value);
    setIsLoading(true);
  };
  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <h2 className='mb-1 text-xl font-bold text-secondary'>
        Daftar Laporan Pelanggan
      </h2>
      <div className='overflow-x-scroll xl:overflow-x-hidden'>
        <div className='flex w-full items-start justify-between gap-1 rounded-t-xl bg-primary p-2 md:flex-row lg:flex-col'>
          <div className="grid grid-cols-2 gap-1 sm:grid-cols-2 lg:grid-cols-12">
            <select
              onChange={monthChange}
              className="select select-sm max-w-xs sm:w-9/12 md:w-full col-span-2"
              value={month}
            >
              <option value="" selected>
                Bulan
              </option>
              <option value="1">Januari</option>
              <option value="2">Februari</option>
              <option value="3">Maret</option>
              <option value="4">April</option>
              <option value="5">Mei</option>
              <option value="6">Juni</option>
              <option value="7">Juli</option>
              <option value="8">Agustus</option>
              <option value="9">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">Desember</option>
            </select>
            <select
              onChange={yearChange}
              className="select select-sm max-w-xs sm:w-3/4 md:w-full"
              value={year}
            >
              <option value="" selected>
                Tahun
              </option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
            </select>

            <div
              style={{ backgroundColor: 'white', border: 1, borderRadius: 8 }}
              className="grid grid-cols-3 sm:w-3/4 md:w-full col-span-3"
            >
              <p
                style={{ color: '#908C8C', paddingLeft: 16, paddingTop: 5 }}
                className="ml-px w-full col-span-2"
              >
                Jumlah Active
              </p>
              <p style={{ paddingLeft: 16, paddingTop: 5 }} className="ml-px">
                {active}
              </p>
            </div>
            <div
              style={{ backgroundColor: 'white', border: 1, borderRadius: 8 }}
              className=" grid grid-cols-4 sm:w-3/4 md:w-full col-span-3"
            >
              <p
                style={{ color: '#908C8C', paddingLeft: 16, paddingTop: 5 }}
                className="ml-px w-full col-span-3"
              >
                Jumlah Non-Active
              </p>
              <p style={{ paddingLeft: 16, paddingTop: 5 }} className="ml-px">
                {nonActive}
              </p>
            </div>
            <div
              style={{ backgroundColor: 'white', border: 1, borderRadius: 8 }}
              className=" grid grid-cols-3 sm:w-3/4 md:w-full col-span-3"
            >
              <p
                style={{ color: '#908C8C', paddingLeft: 16, paddingTop: 5 }}
                className="ml-px w-full col-span-2"
              >
                Jumlah Langganan
              </p>
              <p style={{ paddingLeft: 16, paddingTop: 5 }} className="ml-px">
                {langganan}
              </p>
            </div>
          </div>
        </div>
        <table className="w-full">
          <thead className="bg-gray-200 text-xs uppercase text-mirage-500">
          <tr>
            <th className="px-2 py-3">Tanggal</th>
            <th className="px-2 py-3">Active</th>
            <th className="px-2 py-3">Non-Active</th>
            <th className="px-2 py-3">Langganan</th>
          </tr>
          </thead>
          {isLoading ? (
            ''
          ) : (
            <tbody className="text-thunder">
            {data.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  } border-b hover:!bg-gray-50`}
                >
                  <td className="px-2 py-3 text-center">
                    {dateFormat(item.subscription_create_date, 'DD')}
                  </td>
                  <td className="px-2 py-3 text-center">
                    {item.status_active}
                  </td>
                  <td className="px-2 py-3 text-center">
                    {item.status_non_active === null
                      ? '-'
                      : item.status_non_active}
                  </td>
                  <td className="px-2 py-3 text-center">
                    {item.langganan}
                  </td>
                </tr>
              );
            })}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
};

export default List;