import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { createRef, useRef, useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Cropper } from 'react-cropper';

const Edit = ({ request }) => {
  const params = useParams();
  const navigate = useNavigate();
  const cropperRef = createRef();
  const [croppedKtp, setCroppedKtp] = useState('');
  const [modalKtp, setModalKtp] = useState(false);
  const [imageBlobKtp, setImageBlobKtp] = useState('');
  const deleteRef = useRef(null);
  const [imageKtpName, setImageKtpName] = useState('');

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { isLoading },
  } = useForm({
    // reValidateMode: 'onChange',
    defaultValues: async () => {
      try {
        const data = await request.get(`/customers/${params.id}`);
        return {
          customer_name: data.customer_name,
          customer_email: data.customer_email,
          customer_phone: data.customer_phone,
          customer_whatsapp: data.customer_whatsapp,
          customer_tin: data.customer_tin,
          customer_avatar: data.customer_avatar,
          customer_status: data.customer_status,
          customer_address: data.customer_address,
          customer_ktp: data.customer_ktp,
        };
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    },
  });

  const submitHandler = async (data) => {
    try {
      if (croppedKtp) {
        data.ktp_file = croppedKtp.replace(/^data:image\/[a-z]+;base64,/, '');
        data.ktp_name = imageKtpName;
      }
      let respons = await request.put(`/customers/${params.id}`, data);
      if(respons.success){
        toast.success('Berhasil menyimpan data');
        return navigate('/customers');
      }else {
        toast.success(respons.message);
      }

    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  function onCropKTP() {
    const cropper = cropperRef.current?.cropper;
    if (typeof cropper !== 'undefined') {
      const base64 = cropper.getCroppedCanvas().toDataURL();
      setCroppedKtp(base64);
      setModalKtp(false);
      setValue('customer_ktp', base64);
    }
  }

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Edit Pelanggan</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Nama</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('customer_name', {
                    required: 'Nama harus diisi',
                  })}
                  type='text'
                  placeholder='Ketikan nama pelanggan'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Email</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('customer_email')}
                  type='email'
                  placeholder='Ketikan email pelanggan'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Nomor Telepon</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('customer_phone', {
                    required: 'Nomor Telepon harus diisi',
                  })}
                  type='text'
                  placeholder='Ketikan Nomor Telepon pelanggan'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Nomor Whatsapp</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('customer_whatsapp', {
                    required: 'Nomor Whatsapp harus diisi',
                  })}
                  type='text'
                  placeholder='Ketikan nomor Whatsapp pelanggan'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>NPWP</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('customer_tin')}
                  type='text'
                  placeholder='Ketikan NPWP pelanggan'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <select
                  {...register('customer_status', {
                    required: 'Status harus dipilih',
                  })}
                  className='select select-bordered'
                >
                  <option value='' disabled>
                    Pilih Status Pelanggan
                  </option>
                  <option value='active'>Aktif</option>
                  <option value='non-active'>Non-Aktif</option>
                </select>
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Alamat</span>
              </label>
              {isLoading ? (
                <Skeleton height={95} />
              ) : (
                <textarea
                  {...register('customer_address')}
                  className='textarea textarea-bordered h-24'
                  placeholder='Ketikan alamat pelanggan'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <div className='flex flex-col gap-1'>
                <span className='label-text'>
                  Foto KTP{' '}
                  <span style={{ color: 'red', fontSize: '0.75rem' }}>*</span>
                  <span style={{ color: 'red' }}>(Size max.2Mb)</span>
                </span>
                <label className='label'>
                  {croppedKtp === '' ? (
                    getValues('customer_ktp') !== null ? (
                      <div className='flex flex-col gap-2'>
                        <label
                          htmlFor='customer_ktp'
                          className='cursor-pointer'
                        >
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_SERVICE_URL +
                              '/images/cust_ktp/' +
                              getValues('customer_ktp')
                            }
                            alt='Customer Card'
                            style={{
                              maxWidth: '100%',
                              maxHeight: '200px',
                            }}
                          />
                        </label>
                      </div>
                    ) : (
                      <label
                        htmlFor='customer_ktp'
                        className='cursor-pointer rounded-lg border-2 border-dashed border-[#F2A647] bg-[#f4d0a4] py-10 text-center text-lg font-bold text-[#F3B66A]'
                        onDragOver={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        onDrop={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const file = e.dataTransfer.files[0];
                          if (file) {
                            setImageBlobKtp(URL.createObjectURL(file));
                            setImageKtpName(file.name);
                            setModalKtp(true);
                          }
                        }}
                      >
                        klik atau tarik gambar kesini
                      </label>
                    )
                  ) : (
                    <div className='flex flex-col gap-2'>
                      <label htmlFor='customer_ktp' className='cursor-pointer'>
                        <img
                          src={croppedKtp}
                          alt='Customer Card'
                          style={{
                            maxWidth: '100%',
                            maxHeight: '200px',
                          }}
                        />
                      </label>
                    </div>
                  )}
                </label>
                <input
                  id='customer_ktp'
                  type='file'
                  accept='image/png, image/jpg, image/jpeg'
                  className='file-input file-input-bordered file-input-warning w-full max-w-xs'
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setImageBlobKtp(URL.createObjectURL(file));
                      setImageKtpName(file.name);
                      setModalKtp(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/customers' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
      <Dialog
        open={modalKtp}
        onClose={() => setModalKtp(false)}
        className='relative z-50'
      >
        <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
          <DialogPanel className='max-w-[1080px] rounded-lg border bg-white shadow-lg'>
            <div className='bg-[#F6F6F6] py-[10px] px-[24px] text-2xl font-bold text-[#1C1B1B]'>
              Pangkas gambar
            </div>
            <div className=''>
              <Cropper
                ref={cropperRef}
                src={imageBlobKtp}
                className='h-[400px] max-w-[1080px]'
                minCropBoxHeight={50}
                minCropBoxWidth={100}
                autoCropArea={1}
                responsive={false}
                aspectRatio={16 / 9}
              />
            </div>
            <div className='flex justify-end gap-2 py-4 px-4'>
              <button
                className='rounded-lg bg-blue-500 py-2 px-4 text-sm font-semibold text-white hover:bg-blue-600'
                onClick={onCropKTP}
              >
                crop
              </button>
              <button
                className='rounded-lg bg-red-500 py-2 px-4 text-sm font-semibold text-white hover:bg-red-600'
                onClick={() => {
                  setModalKtp(false);
                  setImageBlobKtp('');
                  setCroppedKtp('');
                }}
                ref={deleteRef}
              >
                cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default Edit;
