const Checkbox = (props) => {
  const { extra, color, ...rest } = props;
  return (
    <input
      type='checkbox'
      className={`checkbox-primary checkbox ${extra}`}
      name='weekly'
      {...rest}
    />
  );
};

export default Checkbox;
