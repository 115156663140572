import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {DateFormat} from "../../../utils/date";
import React, { useState} from "react";
import CurrencyInput from "react-currency-input-field";

const Edit = ({ request }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    defaultValues: async () => {
      try {
        const data = await request.get(`/subscriptions/${params.id}`);
        await  request.get('/packages').then((response) => {
          setPackages(response);
        });
        return {
          subscription_start_date   : DateFormat(data.subscription_start_date,'DD MMM YYYY'),
          subscription_expiry_date  : DateFormat(data.subscription_expiry_date,'DD MMM YYYY'),
          subscription_invoice_day  : data.subscription_invoice_day,
          subscription_fee          : data.subscription_fee,
          package_id                : data.package_id,
          invoice                   :data.invoice.invoice_status
        };
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
              err.response.data.errors[field].map((message) =>
                  toast.error(message)
              )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    },
  });

  const selectDay = [ '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28' ];

  const submitHandler = async (data) => {
    try {
      await request.put(`/subscriptions/${params.id}`, data);
      toast.success('Berhasil menyimpan data');
      return navigate('/subscriptions');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
      <>
        <Card extra={'w-full sm:overflow-auto p-4'}>
          <h2 className='text-xl font-bold text-navy-700'>Edit Langganan</h2>
          <form
              onSubmit={handleSubmit(submitHandler, errorHandler)}
              className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
          >
            <div className='grid grid-cols-2'>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Tanggal Mulai Berlangganan</span>
                </label>
                {isLoading ? (
                    <Skeleton height={45}/>
                ) : (
                    <input
                        {...register('subscription_start_date')}
                        type='date'
                        className='input input-bordered w-full max-w-sm'
                        defaultValue={getValues('subscription_start_date')}
                    />
                )}
              </div>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Tanggal Berakhir Langganan</span>
                </label>
                {isLoading ? (
                    <Skeleton height={45}/>
                ) : (
                    <input
                        {...register('subscription_expiry_date')}
                        type='date'
                        className='input input-bordered w-full max-w-sm'
                        defaultValue={getValues('subscription_expiry_date')}
                    />
                )}
              </div>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Tanggal Pembaharuan</span>
                </label>
                <select
                    {...register('subscription_invoice_day')}
                    className='select select-bordered'
                >
                  {selectDay.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                  ))}
                </select>
              </div>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Harga Langganan (Termasuk PPN 11%)</span>
                </label>
                {isLoading ? (
                    <Skeleton height={45}/>
                ) : (
                    <CurrencyInput
                        {...register('subscription_fee', {
                          required: 'Harga harus diisi',
                        })}
                        prefix='Rp '
                        placeholder='Silahkan masukan harga paket'
                        defaultValue={getValues('subscription_fee')}
                        decimalSeparator=','
                        groupSeparator='.'
                        intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                        className='input input-bordered w-full max-w-sm'
                    />
                )}
              </div>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Paket</span>
                </label>
                <select
                    {...register('package_id')}
                    className='select select-bordered w-full'
                >
                  <option value='' disabled selected>
                    Pilih Paket
                  </option>
                  {packages.map((item) => (
                      <option key={item.package_id} value={item.package_id}>
                        {item.package_name}
                      </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='flex gap-x-2'>
              <button type='submit' className='btn btn-primary'>
                Simpan
              </button>
              <Link to='/subscriptions' className='btn'>
                Batal
              </Link>
            </div>
          </form>
        </Card>
      </>
  );
};

export default Edit;
