import {Route, Routes} from "react-router-dom";
import List from "./components/List";

const ReportCustomers = (props) => {
  return (
    <Routes>
      <Route path='/' element={<List {...props} />} />
    </Routes>
  );
};

export default ReportCustomers;