import React, {useCallback, useMemo, useState} from 'react';
import Card from 'components/card';
import {toast} from 'react-toastify';
import {createColumnHelper} from '@tanstack/react-table';
import {dateFormat} from '../../../utils/date';
import Table from '../../../components/table/Table';
import {FiEdit} from 'react-icons/fi';
import Datepicker from 'react-tailwindcss-datepicker';
import {FaRegShareSquare, FaWhatsapp} from "react-icons/fa";
import {Link} from "react-router-dom";
import { useForm } from 'react-hook-form';

const List = ({request}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState('');
    const [survey, setSurvey] = useState('');
    const [filterDate, setFilterDate] = useState({
        startDate: '',
        endDate: ''
    });
    const [technicians, setTechnicians] = useState([]);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCancelled, setIsModalCancelled] = useState(false);
    const [newSurvey, setNewSurvey] = useState('');
    const [isModalSurveyOpen, setIsModalSurveyOpen] = useState(false);

    const { register, handleSubmit } = useForm();

    function formatWhatsAppNumber(whatsappNumber) {
        let formattedNumber = whatsappNumber.replace(/\s+/g, '').replace(/^0+/, '');
        if (!formattedNumber.startsWith('+62')) {
            formattedNumber = '62' + formattedNumber;
        }
        return formattedNumber;
    }

    const getMessage = (selectedRow) => {
        if (!selectedRow || !selectedPhone) return '';

        const whatsappNumber = formatWhatsAppNumber(selectedRow.submission?.customer?.customer_whatsapp);
        const selectedTechnician = technicians.find(technician => technician.technician_phone === selectedPhone);
        const technicianName = selectedTechnician ? selectedTechnician.technician_name : 'Teknisi';
        const installationCoordinates = selectedRow.installation_coordinates;
        return `Halo ${technicianName},
        %0a%0aBerikut ini adalah data pemasangan yang perlu Anda kerjakan:
        %0a%0aNama: ${selectedRow.submission?.customer?.customer_name}
        %0aAlamat: ${selectedRow.installation_address}
        %0aNo HP: wa.me/%2B${whatsappNumber}
        %0aLink Maps: https://www.google.com/maps?q=${installationCoordinates}
        %0aPaket: ${selectedRow.submission?.package?.package_name}`;
    };

    const handleTechnicianClick = async (info) => {
        setSelectedRow(info.row.original);
        if (!technicians.length) {
            const response = await request.get(`/technician`);
            setTechnicians(response);
        }
        window.generate_modal.show();
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                status: status,
                survey: survey,
                startDate: filterDate.startDate,
                endDate: filterDate.endDate,
            };

            try {
                const response = await request.get('/installations', {params});
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));

                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, status, survey, filterDate] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleStatusChangeDropdown = (event, row) => {
      const selectedStatus = event.target.value;

      setNewStatus(selectedStatus === 'waiting' ? 'executing' : selectedStatus);
      setSelectedRow(row);

      if (selectedStatus === 'cancelled') {
        setIsModalCancelled(true);
      } else {
        setIsModalOpen(true);
      }
    };

    const handleSurveyChangeDropdown = (event, row) => {
      const selectedSurvey = event.target.value;

      setNewSurvey(selectedSurvey === '-' ? '-' : selectedSurvey);
      setSelectedRow(row);
      setIsModalSurveyOpen(true);
    };

    const confirmStatusChange = async () => {
      setRefresh(true);
      if (!selectedRow) return;
      try {
        await request.post(`installations/${selectedRow.installation_id}/status`, { installation_status: newStatus });
        const updatedData = data.map(item =>
          item.installation_id === selectedRow.installation_id ? { ...item, installation_status: newStatus } : item
        );
        setData(updatedData);
        toast.success('Status berhasil diperbarui');
      } catch (err) {
        toast.error('Gagal memperbarui status');
      }
      setIsModalOpen(false);
    };

    const confirmSurveyChange = async () => {
      setRefresh(true);
      if (!selectedRow) return;
      try {
        await request.post(`installations/${selectedRow.installation_id}/survey`, { instalasi_survey: newSurvey });
        const updatedData = data.map(item =>
          item.installation_id === selectedRow.installation_id ? { ...item, instalasi_survey: newSurvey } : item
        );
        setData(updatedData);
        toast.success('Survey berhasil diperbarui');
      } catch (err) {
        toast.error('Gagal memperbarui survey');
      }
      setIsModalSurveyOpen(false);
    };

    const confirmReject = async (data) => {
      setRefresh(true);
      if (!selectedRow) return;
      try {
        await request.post(`/installations/${selectedRow.installation_id}/status`, data);
        toast.success('Instalasi telah ditolak');
        setRefresh(false)
        setIsModalCancelled(false);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) => toast.error(message))
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      } finally {
        setRefresh(false);
      }
    };

    const errorHandler = (errors) => {
      Object.keys(errors).map((key) => {
        if (Array.isArray(errors[key])) {
          return errors[key].map((err) => {
            return toast.error(err.message);
          });
        }
        return toast.error(errors[key].message);
      });
    };

    const statusData = {
      waiting: {
        label: 'Waiting',
        color: 'bg-[#36D3991A] text-[#258D67]',
      },
      cancelled: {
        label: 'Cancelled',
        color: 'bg-[#f1ff3a1A] text-[#0a0a0a]',
      },
      executing: {
        label: 'Executing',
        color: 'bg-[#0000001A] text-[#0a0a0a]',
      },
      executed: {
        label: 'Executed',
        color: 'bg-[#e6462e1A] text-[#0a0a0a]',
      },
    };

    const surveyData = {
      '-': {
        label: '-',
        color: 'bg-[#0000001A] text-[#0a0a0a]',
      },
      siap_tarik: {
        label: 'Siap Tarik',
        color: 'bg-[#36D3991A] text-[#0a0a0a]',
      },
      butuh_tiang: {
        label: 'Butuh Tiang',
        color: 'bg-[#f1ff3a1A] text-[#0a0a0a]',
      },
    };

    const getAvailableStatusOptions = (installation_status) => {
      const getStatusOption = (key) => ({
        value: key,
        label: statusData[key].label,
        className: statusData[key].color,
      });

      const statusOptions = {
        waiting: ['cancelled', 'executing'],
        executing: ['cancelled', 'executed'],
      };

      return statusOptions[installation_status]?.map(getStatusOption) || [];
    };

    const getAvailableSurveyOptions = (instalasi_survey) => {
      const getSurveyOption = (key) => ({
        value: key,
        label: surveyData[key].label,
        className: surveyData[key].color,
      });

      const surveyOptions = {
        '-': ['siap_tarik', 'butuh_tiang'],
        siap_tarik: ['butuh_tiang'],
        butuh_tiang: ['siap_tarik'],
      };

      return surveyOptions[instalasi_survey]?.map(getSurveyOption) || [];
    };

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission.customer.customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div className='min-w-max max-w-2xl'>
                        <Link
                            className='max-w-xs truncate text-sm hover:text-success'
                            aria-disabled={true}
                            to={`/customers/${info.row.original.submission?.customer?.customer_id}`}
                        >
                            {info.getValue()}
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission.customer.reseller.reseller_name', {
                header: () => 'Marketing',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue() === undefined ? '-' : info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_address', {
                header: () => 'Alamat Pemasangan',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_status', {
                header: () => 'Status',
                cell: (info) => {
                  const rowData = info.row.original;
                  const currentStatus = rowData.installation_status;
                  const availableOptions = getAvailableStatusOptions(
                    currentStatus,
                  );

                  return (
                    <select
                      className={`select select-sm ${statusData[currentStatus]?.color || ''}`}
                      value={currentStatus}
                      onChange={(event) => handleStatusChangeDropdown(event, rowData)}
                      disabled={currentStatus === 'executed' || currentStatus === 'cancelled'}
                    >
                      <option key="currentStatus" value={currentStatus} style={{ display: 'none' }}>
                        {statusData[currentStatus]?.label || currentStatus}
                      </option>

                      {availableOptions.map((option) => (
                        option.value !== currentStatus && (
                          <option key={option.value} value={option.value} className={statusData[option.value]?.color}>
                            {option.label}
                          </option>
                        )
                      ))}
                    </select>
                  );
                },
              footer: (info) => info.column.id,
            }),
          columnHelper.accessor('instalasi_survey', {
              header: () => 'Survey',
              cell: (info) => {
                const rowData = info.row.original;
                let currentSurvey = rowData.instalasi_survey;

                if (currentSurvey === null) {
                  currentSurvey = '-';
                }
                const availableOptions = getAvailableSurveyOptions(currentSurvey);

                return (
                  <select
                      className={`select select-sm ${surveyData[currentSurvey]?.color || ''}`}
                      value={currentSurvey}
                      onChange={(event) => handleSurveyChangeDropdown(event, rowData)}
                    >
                      <option value={currentSurvey} disabled>
                        {surveyData[currentSurvey]?.label || currentSurvey}
                      </option>

                      {availableOptions.map((option) => (
                        option.value !== currentSurvey && (
                          <option key={option.value} value={option.value} className={surveyData[option.value]?.color}>
                            {option.label}
                          </option>
                        )
                      ))}
                    </select>
                  );
                },
                footer: (info) => info.column.id,
              }),
            columnHelper.accessor('installation_note', {
              header: () => 'Keterangan',
              cell: (info) => (
                <div className='max-w-xs truncate text-sm'>{info.getValue() === null ? '-' : info.getValue()}</div>
              ),
              footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_execution_date', {
                header: () => 'Tanggal Eksekusi',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue()
                            ? dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')
                            : '-'}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_create_date', {
                header: () => <div className='min-w-max'>Tanggal Pengajuan</div>,
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('-', {
                header: () => 'Aksi',
                cell: (info) => (
                  <div
                    className="flex gap-x-1"
                    style={{ cursor: 'pointer' }}
                  >
                    <a
                      aria-disabled={true}
                      href={`https://web.whatsapp.com/send?phone=${encodeURIComponent(info.row.original.submission?.customer?.customer_whatsapp).replace(/^0/, '62')}&app_absent=0`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-green font-medium"
                    >
                      <FaWhatsapp size={24} />
                    </a>
                    <a href={`installations/${info.row.original.installation_id}`}>
                      <FiEdit size={20} color="#FBBD29" />
                    </a>
                    <div className="inline-flex max-w-2xl gap-x-1 text-sm"
                         style={{ cursor: 'pointer' }}
                         onClick={() => handleTechnicianClick(info)}
                    >
                      {info.getValue()}
                      <FaRegShareSquare size={20} className="font-medium text-success" />
                    </div>
                  </div>
                ),
              footer: (info) => info.column.id,
            }),
        ],
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-semibold">Konfirmasi Perubahan Status</h3>
            <p className="mt-2 text-sm">Apakah Anda yakin ingin mengubah status menjadi</p>
            <p className="mt-2 text-sm"><strong>{newStatus === 'executing' ? 'Executing' : statusData[newStatus]?.label}?</strong></p>
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="btn"
                onClick={() => setIsModalOpen(false)}
              >
                Batal
              </button>
              <button
                className="btn btn-success"
                onClick={confirmStatusChange}
              >
                Ubah
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalCancelled && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <form
            onSubmit={handleSubmit(confirmReject, errorHandler)}
            method="dialog"
            className="modal-box"
          >
            <h3 className="text-lg font-bold">Konfirmasi Penolakan</h3>
            <p className="py-4">
              Anda yakin ingin membatalkan, proses instalasi akan di batalkan!
            </p>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Alasan Penolakan</span>
              </label>
              <textarea
                {...register('installation_note', {
                  required: 'Alasan Penolakan harus diisi',
                })}
                className="textarea textarea-bordered h-32 w-full"
                placeholder="Masukan alasan penolakan"
              />
            </div>
            <div className="modal-action">
              {/* if there is a button in form, it will close the modal */}
              <button type="button" className="btn" onClick={() => setIsModalCancelled(false)}>
                Batal
              </button>
              <button type="submit" className="btn btn-accent">
                Konfirmasi
              </button>
            </div>
          </form>
        </div>
      )}
      {isModalSurveyOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-semibold">Konfirmasi Perubahan Survey</h3>
            <p className="mt-2 text-sm">Apakah Anda yakin ingin mengubah survey menjadi</p>
            <p className="mt-2 text-sm"><strong>{newSurvey === '-' ? '-' : surveyData[newSurvey]?.label}?</strong></p>
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="btn"
                onClick={() => setIsModalSurveyOpen(false)}
              >
                Batal
              </button>
              <button
                className="btn btn-success"
                onClick={confirmSurveyChange}
              >
                Ubah
              </button>
            </div>
          </div>
        </div>
      )}
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className="mb-1 text-xl font-bold text-secondary">
          Daftar Instalasi
        </h2>
        <div className="overflow-x-scroll xl:overflow-x-hidden">
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            filters={() => {
              return (
                <>
                  <select name="" id="" value={status} className="select select-sm"
                          onChange={(e) => setStatus(e.target.value)}>
                    <option value="">semua</option>
                    <option value="waiting">waiting</option>
                    <option value="cancelled">cancelled</option>
                    <option value="executing">executing</option>
                    <option value="executed">executed</option>
                  </select>
                  <select name="" id="" value={survey} className="select select-sm"
                          onChange={(e) => setSurvey(e.target.value)}>
                    <option value="">semua</option>
                    <option value="siap_tarik">siap tarik</option>
                    <option value="butuh_tiang">butuh tiang</option>
                  </select>
                  <Datepicker
                    maxDate={new Date()}
                    toggleClassName="relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                    containerClassName="input input-sm relative flex bg-white justify-self-start"
                    inputClassName="outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e)}
                    showShortcuts={true}
                    placeholder="Pilih Tanggal Pengajuan"
                  />
                </>
              )
            }}
          />
        </div>
      </Card>
      <dialog
        id="generate_modal"
        className="modal modal-bottom sm:modal-middle"
      >
        <form method="dialog" className="modal-box">
          <h3 className="mb-2 text-lg font-bold">Teknisi</h3>
          <div className="form-control w-full max-w-sm">
          <select
                            id='technician_phone'
                            className='select select-bordered'
                            onChange={(e) => setSelectedPhone(e.target.value)}
                            value={selectedPhone}
                        >
                            <option value=''>pilih nomor teknisi</option>
                            {technicians.map((technician) => (
                                <option key={technician.technician_id} value={technician.technician_phone}>
                                    {`${technician.technician_name} - ${technician.technician_phone}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='modal-action'>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                                const message = getMessage(selectedRow);
                                window.open(
                                    `https://web.whatsapp.com/send?phone=62${selectedPhone.substring(1, selectedPhone.length)}&text=${message}&app_absent=0`,
                                    '_blank'
                                );
                            }}
                        >
                            Share
                        </button>
                        <button className="btn">Batal</button>
                    </div>
                </form>
            </dialog>
        </div>
    );
};
export default List;
