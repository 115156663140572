import React, {useCallback, useMemo, useState} from 'react';
import Card from 'components/card';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';
import { dateFormat } from '../../../utils/date';
import Table from '../../../components/table/Table';
import {FiEdit} from 'react-icons/fi';
import Datepicker from 'react-tailwindcss-datepicker';
import {Link} from "react-router-dom";

const List = ({ request }) => {
    const [isLoading, setIsLoading]   = useState(false);
    const [data, setData]             = useState([]);
    const [pageCount, setPageCount]   = useState(0);
    const [totalRow, setTotalRow]     = useState(0);
    const [refresh, setRefresh]       = useState(false);
    const [status, setStatus]         = useState('');
    const [filterDate, setFilterDate] = useState({
        startDate: '',
        endDate: ''
    });

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                status: status,
                startDate: filterDate.startDate,
                endDate: filterDate.endDate,
            };

            try {
                const response = await request.get('/tiket', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));

                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, status, filterDate] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_number.customer.customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div className='min-w-max max-w-2xl'>
                        <Link
                            className='max-w-xs truncate text-sm hover:text-success'
                            aria-disabled={true}
                            to={`/customers/${info.row.original.customer_number.customer.customer_id}`}
                        >
                            {info.getValue()}
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('s_code', {
                header: () => 'Tiket Code',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue() === undefined ? '-' : info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('tikets.tiket_name', {
                header: () => 'Pengaduan',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('s_desc', {
                header: () => 'Keterangan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue()
                            ? dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')
                            : '-'}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_number.customer.customer_address', {
                header: () => 'Alamat Pelanggan',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('s_tiket_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'new' ? (
                            <span className='badge badge-error'>Baru</span>
                        ) : info.getValue() === 'process' ? (
                            <span className='badge badge-warning'>Proses</span>
                        ) : info.getValue() === 'done' ? (
                            <span className='badge badge-success'>Selesai</span>
                        ) :  (
                            <span className='badge'>{info.getValue()}</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('s_tiket_create_date', {
                header: () => <div className='min-w-max'>Tanggal Pengaduan</div>,
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('s_tiket_id', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div
                        className='max-w-2xl truncate text-xs flex gap-x-1'
                        style={{ cursor: 'pointer' }}
                    >
                        <a href={`tiket/${info.getValue()}`}>
                            <FiEdit size={20} color='#FBBD29' />
                        </a>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <div>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>
                    Daftar Pengaduan
                </h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => {
                            return (
                                <>
                                    <select name="" id="" value={status} className='select select-sm' onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">Semua</option>
                                        <option value="new">Baru</option>
                                        <option value="process">Proses</option>
                                        <option value="done">Selesai</option>
                                    </select>
                                    <Datepicker
                                        maxDate={new Date()}
                                        toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                        containerClassName='input input-sm relative flex bg-white justify-self-start'
                                        inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                                        value={filterDate}
                                        onChange={(e) => setFilterDate(e)}
                                        showShortcuts={true}
                                        placeholder='Pilih Tanggal Pengaduan'
                                    />
                                </>
                            )
                        }}
                    />
                </div>
            </Card>
        </div>
    );
};
export default List;
