import Card from "../../../components/card";
import React, {useCallback, useMemo, useState} from "react";
import { Link, useParams } from 'react-router-dom';
import Table from "../../../components/table/Table";
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import {dateFormat} from "../../../utils/date";
import { convertToRupiah } from '../../../utils/general';

const ReportDiscountDetail = ({ request }) => {
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);

      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        invoice_date: param.id
      };

      try {

        const response = await request.get('/report-discount-invoice', { params });
        const startIndex = response.detail.from;

        const updatedData = response.detail.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
          invoiceItems: item.invoice_items,
        }));
        setData(updatedData);
        setPageCount(response.detail.last_page);
        setTotalRow(response.detail.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer.customer_name', {
        header: () => 'Pelanggan',
        cell: (info) => (
          <div>
            <div className='min-w-max max-w-2xl'>
              <Link
                className='max-w-xs truncate text-sm hover:text-success'
                aria-disabled={true}
                to={`/customers/${info.row.original.subscription.customer_number.customer_id}`}
              >
                {info.getValue()}
                <br/>{info.row.original.subscription.customer_number.c_number_value}
              </Link>
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_no', {
        header: () => 'Nomor Invoice',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_issue_date', {
        header: () => 'Jatuh Tempo',
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YY')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("invoiceItems", {
        header: () => "Kompensasi",
        cell: (info) => {
          const compensationItem = info.getValue().find((item) => item.i_item_type === "compensation");
          return (
            <div className="text-sm">
              {compensationItem ? convertToRupiah(compensationItem.i_item_fee, true) : 0}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("invoiceItems", {
        header: () => "Promo",
        cell: (info) => {
          const promoItem = info.getValue().find((item) => item.i_item_type === "promo");
          return (
            <div className="text-sm">
              {promoItem ? convertToRupiah(promoItem.i_item_fee, true) : 0}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('total_invoice', {
        header: () => 'Total Diskon',
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm'>
            {convertToRupiah(info.getValue(), true)}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <h2 className='mb-1 text-xl font-bold text-secondary'>
        Detail Laporan Diskon Invoice
      </h2>
      <div className='overflow-x-scroll xl:overflow-x-hidden'>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          fetchData={fetchData}
          setRefresh={setRefresh}
          pageCount={pageCount}
          totalRow={totalRow}
        />
      </div>
    </Card>
  );
}

export default ReportDiscountDetail;