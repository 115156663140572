import React, {useMemo, useState, useCallback, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiEdit, FiEye } from 'react-icons/fi';
import { FaRegShareSquare, FaWhatsapp } from 'react-icons/fa';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'format-number';
import Datepicker from 'react-tailwindcss-datepicker';
import Table from 'components/table/Table';
import Card from 'components/card';
import { dateFormat } from 'utils/date';
import {CheckCircleIcon} from "lucide-react";
import {FaMapLocationDot} from "react-icons/fa6";

const List = ({request}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [coverageStatus, setCoverageStatus] = useState('');
    const [technicians, setTechnicians] = useState([]);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dataProvinces, setProvinces] = useState([]);
    const [dataDistrict, setDistrict] = useState([]);
    const [dataSubdistrict, setSubdistrict] = useState([]);
    const [dataWards, setWards] = useState([]);
    const [dataHamlets, setHamlets] = useState([]);
    const [provinceId, setprovinceId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [subdistrictId, setSubdistrictId] = useState('');
    const [hamletId, setHamletId] = useState('');
    const [wardsId, setWardsId] = useState('');

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });
    }, [request]);

    const handleProvince = (event) => {
        setprovinceId(event.target.value);
        setDistrictId('');
        const params = {
            province_id: event.target.value,
        };
        request.get('/districts', {params}).then((response) => {
            setDistrict(response);
        });
    };

    const handleDistrict = (event) => {
        setDistrictId(event.target.value);
        setSubdistrictId('');
        const params = {
            district_id: event.target.value,
        };
        request.get('/subdistricts', {params}).then((response) => {
            setSubdistrict(response);
        });
    };

    const handleSubdistrict = (event) => {
        setSubdistrictId(event.target.value);
        setWardsId('');
        const params = {
            subdistrict_id: event.target.value,
        };
        request.get('/wards', {params}).then((response) => {
            setWards(response);
        });
    };

    const handleWard = (event) => {
        setWardsId(event.target.value);
        setHamletId('');
        const params = {
            ward_id: event.target.value,
        };
        request.get('/hamlets', {params}).then((response) => {
            setHamlets(response);
        });
    };

    const handleHamlet = (event) => {
        setHamletId(event.target.value);
    };

    function formatWhatsAppNumber(whatsappNumber) {
        let formattedNumber = whatsappNumber.replace(/\s+/g, '').replace(/^0+/, '');
        if (!formattedNumber.startsWith('+62')) {
            formattedNumber = '62' + formattedNumber;
        }
        return formattedNumber;
    }

    const getMessage = (selectedRow) => {
        if (!selectedRow || !selectedPhone) return '';

        const whatsappNumber = formatWhatsAppNumber(selectedRow.customer_whatsapp);
        const selectedTechnician = technicians.find(
            (technician) => technician.technician_phone === selectedPhone
        );
        const technicianName = selectedTechnician
            ? selectedTechnician.technician_name
            : 'Teknisi';
        const submissionCoordinates = selectedRow.submission_coordinates;
        return `Halo ${technicianName},
        %0a%0aBerikut ini adalah data pemasangan yang perlu Anda kerjakan:
        %0a%0aNama: ${selectedRow.customer_name}
        %0aAlamat: ${selectedRow.submission_address}
        %0aNo HP: wa.me/%2B${whatsappNumber}
        %0aLink Maps: https://www.google.com/maps?q=${submissionCoordinates}
        %0aPaket: ${selectedRow.package_name}`;
    };

    const handleTechnicianClick = async (info) => {
        setSelectedRow(info.row.original);
        if (!technicians.length) {
            const response = await request.get(`/technician`);
            setTechnicians(response);
        }
        window.generate_modal.show();
    };

    const [value, setValue] = useState({
        startDate: null,
        endDate: null,
    });

    const handleValueChange = (newValue) => {
        setValue(newValue);
    };

    const handleSubmissionStatusChange = (event) => {
        setSubmissionStatus(event.target.value);
    };

    const handleCoverageStatusChange = (event) => {
        setCoverageStatus(event.target.value);
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                start_date: value?.startDate,
                end_date: value?.endDate,
                submission_status: submissionStatus,
                coverage_status: coverageStatus,
                province_id: provinceId,
                district_id: districtId,
                subdistrict_id: subdistrictId,
                ward_id: wardsId,
                hamlet_id: hamletId,
            };
            try {
                const response = await request.get('/submissions', {params});
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, value, submissionStatus, coverageStatus, provinceId, districtId, subdistrictId, wardsId, hamletId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='min-w-max max-w-2xl'>
                        <Link
                            className='max-w-xs truncate text-sm hover:text-success'
                            aria-disabled={true}
                            to={`/customers/${info.row.original.customer_id}`}
                        >
                            {info.getValue()}
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller_name', {
                header: () => 'Marketing',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>
                        {info.getValue() === null ? '-' : info.getValue()}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('package_name', {
                header: () => 'Paket',
                cell: (info) => (
                    <div className='min-w-max text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('package_price', {
                header: () => 'Harga',
                cell: (info) =>
                    info.getValue() > 0 ? (
                        <div className='min-w-max text-sm'>
                            Rp{' '}
                            {format({
                                prefix: '',
                                integerSeparator: '.',
                            })(parseFloat(info.getValue()))}
                        </div>
                    ) : (
                        <div>-</div>
                    ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor((row) => `${row.district_name}, ${row.subdistrict_name}, ${row.ward_name}, ${row.hamlet_name}`, {
                id: 'alamat',
                header: () => 'Alamat Pengajuan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission_create_date', {
                header: () => 'Tanggal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_ktp', {
                header: () => 'KTP',
                cell: (info) => (
                    <div className='flex justify-center'>
                        {info.getValue() ? (
                            <CheckCircleIcon className='text-green-500' size={20}/>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('-', {
                header: () => 'Terjangkau',
                cell: (info) => {
                    const customerSubmission = info.row.original;

                    const isTerjangkau = customerSubmission.zone_id != null;

                    return (
                        <div className='max-w-2xl truncate text-sm'>
                            {isTerjangkau ? (
                                <span className='badge badge-success'>covered</span>
                            ) : (
                                <span className='badge badge-warning'>uncovered</span>
                            )}
                        </div>
                    );
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue() === 'approved' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : info.getValue() === 'new' ? (
                            <span className='badge badge-info'>{info.getValue()}</span>
                        ) : info.getValue() === 'cancelled' ? (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) : info.getValue() === 'rejected' ? (
                            <span className='badge badge-warning'>{info.getValue()}</span>
                        ) : (
                            <span className='badge badge-neutral'>{info.getValue()}</span>
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission_id', {
                header: () => 'AKSI',
                cell: (info) => {
                    const { submission_id, submission_coordinates} = info.row.original;
                    return (
                        <div className='flex gap-x-1'>
                            {submission_coordinates && (
                                <a
                                    href={`https://www.google.com/maps?q=${submission_coordinates}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='text-blue-500 underline'
                                >
                                    <FaMapLocationDot size={20}/>
                                </a>
                            )}
                            <a
                                aria-disabled={true}
                                href={`https://web.whatsapp.com/send?phone=${encodeURIComponent(info.row.original.customer_whatsapp)}&text=${encodeURIComponent('Hallo')}&app_absent=0`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-green font-medium"
                            >
                                <FaWhatsapp size={24} />
                            </a>
                            <Link
                                aria-disabled={true}
                                to={`/submissions/${submission_id}`}
                                className='font-medium text-success'
                            >
                                <FiEye size={24}/>
                            </Link>
                            <Link
                                to={`/submissions/${submission_id}/edit`}
                                className='font-medium text-warning'
                            >
                                <FiEdit size={20}/>
                            </Link>
                            <div
                                className='inline-flex max-w-2xl gap-x-1 text-sm'
                                style={{cursor: 'pointer'}}
                                onClick={() => handleTechnicianClick(info)}
                            >
                                <FaRegShareSquare size={20} color='#FBBD29'/>
                            </div>
                        </div>
                    );
                },
                footer: (info) => info.column.id,
            }),
        ],
        [data] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Daftar Pengajuan</h2>
                <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleSubmissionStatusChange}
                                        className='select select-sm max-w-xs'
                                        value={submissionStatus}
                                    >
                                        <option value='' selected>
                                            Semua Status Pengajuan
                                        </option>
                                        <option value='new'>New</option>
                                        <option value='approved'>Approved</option>
                                        <option value='planned'>Planned</option>
                                        <option value='rejected'>Rejected</option>
                                        <option value='cancelled'>Cancelled</option>
                                    </select>
                                    <Datepicker
                                        maxDate={new Date()}
                                        toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                        containerClassName='input input-sm relative flex bg-white justify-self-start'
                                        inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                                        value={value}
                                        onChange={handleValueChange}
                                        showShortcuts={true}
                                        placeholder='Pilih Tanggal Pengajuan'
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleCoverageStatusChange}
                                        className='select select-sm max-w-xs'
                                        value={coverageStatus}
                                    >
                                        <option value='' selected>
                                            Semua Status Jangkauan
                                        </option>
                                        <option value='covered'>Covered</option>
                                        <option value='uncovered'>Uncovered</option>
                                    </select>
                                    <select
                                        onChange={handleProvince}
                                        value={provinceId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Provinsi
                                        </option>
                                        {dataProvinces.map((item) => (
                                            <option key={item.province_id} value={item.province_id}>
                                                {item.province_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleDistrict}
                                        value={districtId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Kabupaten
                                        </option>
                                        {dataDistrict.map((item) => (
                                            <option key={item.district_id} value={item.district_id}>
                                                {item.district_name}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleSubdistrict}
                                        value={subdistrictId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Kecamatan
                                        </option>
                                        {dataSubdistrict.map((item) => (
                                            <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                                {item.subdistrict_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <select
                                        onChange={handleWard}
                                        value={wardsId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Desa
                                        </option>
                                        {dataWards.map((item) => (
                                            <option key={item.ward_id} value={item.ward_id}>
                                                {item.ward_name}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleHamlet}
                                        value={hamletId}
                                        className='select select-sm max-w-xs'
                                    >
                                        <option value='' disabled selected>
                                            Pilih Dusun
                                        </option>
                                        {dataHamlets.map((item) => (
                                            <option key={item.hamlet_id} value={item.hamlet_id}>
                                                {item.hamlet_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                    />
                </div>
            </Card>
            <dialog
                id='generate_modal'
                className='modal modal-bottom sm:modal-middle'
            >
                <form method='dialog' className='modal-box'>
                    <h3 className='mb-2 text-lg font-bold'>Teknisi</h3>
                    <div className='form-control w-full max-w-sm'>
                        <select
                            id='technician_phone'
                            className='select select-bordered'
                            onChange={(e) => setSelectedPhone(e.target.value)}
                            value={selectedPhone}
                        >
                            <option value=''>pilih nomor teknisi</option>
                            {technicians.map((technician) => (
                                <option
                                    key={technician.technician_id}
                                    value={technician.technician_phone}
                                >
                                    {`${technician.technician_name} - ${technician.technician_phone}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='modal-action'>
                        <button
                            type='button'
                            className='btn btn-success'
                            onClick={() => {
                                const message = getMessage(selectedRow);
                                window.open(
                                    `https://wa.me/send?phone=62${selectedPhone.substring(
                                        1,
                                        selectedPhone.length
                                    )}&text=${message}&app_absent=0`,
                                    '_blank'
                                );
                            }}
                        >
                            Share
                        </button>
                        <button className='btn'>Batal</button>
                    </div>
                </form>
            </dialog>
        </>
    );
};

export default List;
