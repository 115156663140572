import {Route, Routes} from "react-router-dom";
import List from './Components/List';
import Add from './Components/Add';
import Edit from './Components/Edit';

const Area = (props) =>{
    return(
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/add' element={<Add {...props} />} />
            <Route path='/:id/edit' element={<Edit {...props} />} />
        </Routes>
    );
};

export default Area;